import React from 'react';

function OCLogo(props) {
  return (
    <img
      alt="OCLogo"
      src="/static/OCLogo.png"
      {...props}
    />
  );
}

export default OCLogo;
