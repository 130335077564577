/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import moment from 'moment';
import { msalInstance, loginRequest } from '../authProvider';

class AuthService {
  setAxiosInterceptors = () => {
    axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        if (err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const loggedInUser = JSON.parse(this.getLoggedInUser());
              if (!loggedInUser) {
                window.location.reload();
                return Promise.reject(err);
              }

              const response = await axios.post('api/user/refreshToken', '', {
                headers: {
                  RefreshAuth: `Basic ${btoa(
                    `${loggedInUser.id}:${loggedInUser.refreshToken}`
                  )}`,
                },
              });
              const { data } = response;
              if (data) {
                this.setSession(data.token);
                localStorage.setItem('accessToken', data.token);
                localStorage.setItem('loggedInUser', JSON.stringify(data));
                err.config.headers.Authorization = `Bearer ${data.token}`;
                err.config.baseURL = undefined;

                localStorage.setItem('accessToken', data.token);
                return axios.request(err.config);
              }
            } catch (_error) {
              if (_error?.response?.status === 400) {
                this.setSession(null);
              }
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err);
      }
    );
  };

  setDefaults() {
    this.setTicketTypes();
    this.setTicketPriorities();
    this.setTicketResolutionTypes();
    this.setCacheExpiry();
  }

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    axios
      .post('api/user/login', '', {
        headers: {
          Authorization: `Basic ${btoa(`${email}:${password}`)}`,
        },
      })
      .then((response) => {
        const { data } = response;
        if (!data) {
          reject(new Error('Invalid Username or Password'));
        }

        if (data) {
          this.setSession(data.token);
          localStorage.setItem('accessToken', data.token);
          localStorage.setItem('loggedInUser', JSON.stringify(data));
          resolve(data);
        } else {
          reject(data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  loginInWithToken = () => new Promise((resolve, reject) => {
    const loggedInUser = JSON.parse(this.getLoggedInUser());

    if (!loggedInUser) {
      reject(new Error('Expired Token'));
    }

    if (loggedInUser.givenName) {
      const user = {
        id: loggedInUser.id,
        position: loggedInUser.jobTitle,
        email: loggedInUser.mail,
        firstName: loggedInUser.givenName,
        lastName: loggedInUser.surname,
        phoneNumber: loggedInUser.mobilePhone,
        organisation: loggedInUser.organisation,
        role: 'Admin',
        avatar: loggedInUser.avatar,
      };

      localStorage.setItem('loggedInUser', JSON.stringify(user));
      resolve(user);
    } else {
      resolve(loggedInUser);
    }
  });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      axios.defaults.headers.put['Content-Type'] = 'application/json';

      this.setTicketTypes();
      this.setTicketPriorities();
      this.setTicketResolutionTypes();
      this.setCacheExpiry();
    } else {
      if (localStorage.getItem('azureUsername')) {
        msalInstance.logout({
          onRedirectNavigate: () => false,
        });
      }
      localStorage.clear();
    }
  };

  setTicketTypes = () => {
    if (!this.getBasicAccount()) {
      return;
    }
    const ticketTypes = JSON.parse(localStorage.getItem('ticketTypes'));
    const expiry = JSON.parse(localStorage.getItem('cacheExpiry'));
    if (ticketTypes && expiry && moment(expiry) > moment()) {
      return;
    }

    axios
      .get('/api/ticketTypes')
      .then((response) => {
        localStorage.setItem('ticketTypes', JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setTicketPriorities = () => {
    if (!this.getBasicAccount()) {
      return;
    }

    const ticketPriority = JSON.parse(localStorage.getItem('ticketPriority'));
    const expiry = JSON.parse(localStorage.getItem('cacheExpiry'));
    if (ticketPriority && expiry && moment(expiry) > moment()) {
      return;
    }

    axios
      .get('/api/ticketPriority')
      .then((response) => {
        localStorage.setItem('ticketPriority', JSON.stringify(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setTicketResolutionTypes = () => {
    if (!this.getBasicAccount()) {
      return;
    }

    const ticketResolutionTypes = JSON.parse(
      localStorage.getItem('ticketResolutionTypes')
    );
    const expiry = JSON.parse(localStorage.getItem('cacheExpiry'));
    if (ticketResolutionTypes && expiry && moment(expiry) > moment()) {
      return;
    }

    axios
      .get('/api/ticketResolutionTypes')
      .then((response) => {
        localStorage.setItem(
          'ticketResolutionTypes',
          JSON.stringify(response.data)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setCacheExpiry = () => {
    if (!this.getBasicAccount()) {
      return;
    }

    const expiry = JSON.parse(localStorage.getItem('cacheExpiry'));
    if (expiry && moment(expiry) > moment()) {
      return;
    }

    localStorage.setItem(
      'cacheExpiry',
      JSON.stringify(
        moment()
          .add(15, 'minutes')
          .format()
      )
    );
  };

  getBasicAccount = () => {
    const username = localStorage.getItem('azureUsername');
    if (!username) {
      return localStorage.getItem('accessToken');
    }

    const account = msalInstance.getAccountByUsername(username);
    if (!account) {
      return undefined;
    }

    return username;
  };

  getAccessToken = async () => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';

    const username = localStorage.getItem('azureUsername');
    if (!username) {
      return localStorage.getItem('accessToken');
    }

    const account = msalInstance.getAccountByUsername(username);
    if (!account) {
      return undefined;
    }

    try {
      const token = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account,
      });
      return token.accessToken;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  getDevopsToken = async () => {
    const username = localStorage.getItem('azureUsername');
    if (!username) {
      return undefined;
    }

    const account = msalInstance.getAccountByUsername(username);
    if (!account) {
      return undefined;
    }

    try {
      const token = await msalInstance.acquireTokenSilent({
        scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
        account,
      });

      return token.accessToken;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  getAzureCalendarToken = async () => {
    const username = localStorage.getItem('azureUsername');
    if (!username) {
      return undefined;
    }

    const account = msalInstance.getAccountByUsername(username);
    if (!account) {
      return undefined;
    }

    try {
      const token = await msalInstance.acquireTokenSilent({
        scopes: [
          'https://graph.microsoft.com/Calendars.ReadWrite',
          'https://graph.microsoft.com/ChannelMessage.Send',
        ],
        account,
      });

      return token.accessToken;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  getLoggedInUser = () => localStorage.getItem('loggedInUser');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = async () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
