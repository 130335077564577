import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 10px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '20px 10px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    backgroundColor: '#E2E5E9',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  showAlert: ShowAlert,
  ...rest
}) {
  const classes = useStyles();

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
        >
          {Icon && (

          <Icon
            size="20"
          />
          )}

          {Info && <Info className={classes.info} />}
        </Button>
        <Typography
          variant="h6"
        >
          {title}
        </Typography>

      </Grid>

    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  showAlert: PropTypes.bool
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
