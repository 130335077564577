import React, { useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  IconButton,
  SvgIcon,
  Tooltip,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { Gift as GiftIcon } from 'react-feather';
import { useHistory } from 'react-router';
import axios from 'src/utils/axios';

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

function TicketAssign() {
  const classes = useStyles();
  const ref = useRef(null);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [questionOpen, setQuestionOpen] = useState(false);
  const [questionTitle, setQuestionTitle] = useState('');
  const [questionMessage, setQuestionMessage] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleQuestionClose = (answer) => {
    if (!answer) {
      setQuestionOpen(false);
      return;
    }

    if (questionTitle === 'Auto Assign Ticket') {
      setBackdropOpen(true);
      axios.post('/api/ticket/AutoAssignTicket', null)
        .then((result) => {
          setBackdropOpen(false);
          if (result.data.successful) {
            history.push(`/app/queue/all/${result.data.info}`);
          } else {
            enqueueSnackbar(result.data.info, {
              variant: result.data.info === 'No Tickets Left in Queue' ? 'success' : 'error'
            });
          }
        })
        .catch((error) => {
          setBackdropOpen(false);
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        });
    }

    setQuestionOpen(false);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Tooltip title="Auto Assign Ticket">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={() => {
            setQuestionTitle('Auto Assign Ticket');
            setQuestionMessage('Assign next available ticket?');
            setQuestionOpen(true);
          }}
        >
          <SvgIcon fontSize="small">
            <GiftIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>

      <Dialog
        open={questionOpen}
        onClose={() => handleQuestionClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{questionTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {questionMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleQuestionClose(true)} color="secondary" variant="contained">
            Yes
          </Button>
          <Button onClick={() => handleQuestionClose(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default TicketAssign;
