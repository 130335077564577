import React, {
  useState,
  useEffect
} from 'react';
import {
  Box,
  Button,
  Portal,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import LoginView from 'src/views/auth/LoginView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginLeft: 10,
  }
}));

function NewsLoginNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const account = useSelector((state) => state.account);
  const [ignoreNewsLogin, setIgnoreNewsLogin] = useState(localStorage.getItem('ignoreNewsLogin') === 'true');

  const handleLogin = () => {
    setLoginOpen(true);
  };

  useEffect(() => {
    if (account?.user === null && !ignoreNewsLogin) {
      setOpen(true);
    }
  }, [ignoreNewsLogin, account]);

  useEffect(() => {
    const ignoreNewsLoginChanged = () => {
      setIgnoreNewsLogin(localStorage.getItem('ignoreNewsLogin') === 'true');
      if (localStorage.getItem('loggedInUser') !== null) {
        setOpen(false);
      }
    };

    window.addEventListener('storage', ignoreNewsLoginChanged);

    return () => {
      window.removeEventListener('storage', ignoreNewsLoginChanged);
    };
  }, []);

  if (!open) {
    return null;
  }

  const ignoreClick = () => {
    localStorage.setItem('ignoreNewsLogin', true);
    window.dispatchEvent(new Event('storage'));
    setOpen(false);
  };

  return (
    <>
      <Dialog open={loginOpen} onClose={() => setLoginOpen(false)}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <LoginView />
        </DialogContent>
      </Dialog>

      <Portal>
        <div className={classes.root}>
          <Typography
            variant="body1"
            color="inherit"
          >
            Please login with your ConnectX account to gain access to all the
            ConnectX News features, such as Favourites, Relevant Articles and more.

          </Typography>
          <Box
            mt={2}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              onClick={handleLogin}
              variant="contained"
              className={classes.action}
            >
              Login
            </Button>

            <Button
              onClick={ignoreClick}
              variant="contained"
              className={classes.action}
            >
              Next Time
            </Button>
          </Box>
        </div>
      </Portal>
    </>
  );
}

export default NewsLoginNotification;
