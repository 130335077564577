/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_BOARD, MOVE_CARD } from 'src/actions/sprintActions';

const initialState = {
  isLoaded: false,
  lists: [],
  cards: [],
  members: [],
};

const sprintReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOARD: {
      const { lists, cards, members } = action.payload;

      return produce(state, (draft) => {
        draft.lists = lists;
        draft.cards = cards;
        draft.members = members;
        draft.isLoaded = true;
      });
    }

    case MOVE_CARD: {
      const { cardId, listId } = action.payload;

      return produce(state, (draft) => {
        // If listId arg exists, it means that
        // we have to add the card to the new list
        if (listId) {
          const card = draft.cards.filter((x) => x.id === cardId)[0];
          if (card) {
            card.listId = listId;
          }
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default sprintReducer;
