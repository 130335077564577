import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  useTheme,
} from '@material-ui/core';
import * as Icons from 'react-feather';
import Confetti from 'react-confetti';
import BadgeIcon from 'src/components/BadgeIcon';
import PropTypes from 'prop-types';

function BadgeUnlock({ badge, showBadge, handleBadgeClosed }) {
  const [explode, setExplode] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (showBadge) {
      setExplode(true);
    }
  }, [showBadge]);

  return (
    <>
      {!window.location.pathname.includes('app/reports/myDashboard')
      || !badge ? null : (
        <div>
          <Dialog open={showBadge} onClose={handleBadgeClosed} fullWidth>
            <Typography
              variant="h4"
              style={{ paddingLeft: 10, paddingTop: 10 }}
            >
              You just unlocked a new badge.
            </Typography>
            <DialogContent>
              <Card
                p={2}
                style={{
                  marginTop: 10,
                  textAlignLast: 'center',
                  marginRight: 10,
                }}
              >
                <Box style={{ display: 'flex' }}>
                  <Box flex={1} />
                  <Button
                    variant="contained"
                    style={{
                      height: 20,
                      marginBottom: -40,
                      marginTop: 5,
                      marginRight: 5,
                      backgroundColor: '#4CAF50',
                      color: 'white',
                    }}
                  >
                    +
                    {badge.Points}
                  </Button>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 10,
                  }}
                >
                  <BadgeIcon
                    OverlayIcon={Icons[badge.Icon]}
                    colour={badge.Colour}
                  />
                </Box>
                <Typography variant="h3">{badge.Title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {badge.Description}
                </Typography>

                <Divider />

                <Typography
                  variant="h3"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  {badge.Level === 0 ? 'Limited' : `Level ${badge.Level}`}
                </Typography>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleBadgeClosed} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {explode && (
            <Confetti
              style={{ zIndex: theme.zIndex.modal + 1 }}
              height={window.innerHeight}
              onConfettiComplete={() => setExplode(false)}
              recycle={false}
              initialVelocityY={-20}
            />
          )}
        </div>
        )}
    </>
  );
}

BadgeUnlock.propTypes = {
  badge: PropTypes.object,
  showBadge: PropTypes.bool,
  handleBadgeClosed: PropTypes.func,
};

export default BadgeUnlock;
