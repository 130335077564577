import axios from 'src/utils/axios';

export const GET_ALL = '@organisations/get-all';
export const GET_CUSTOMERS = '@organisations/get-customers';
export const RESET = '@organisations/reset';

export function getAll() {
  const request = axios.get('/api/organisations/listing');

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_ALL,
      payload: response.data
    }));
  };
}

export function getCustomers(organisationId) {
  const request = axios.get('/api/organisations/CustomerListing', {
    params: {
      organisationId
    }
  });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_CUSTOMERS,
      payload: response.data
    }));
  };
}

export function reset() {
  return (dispatch) => {
    (
      dispatch({
        type: RESET,
        payload: null
      })
    );
  };
}
