import { PublicClientApplication } from '@azure/msal-browser';
import {
  ImplicitMSALAuthenticationProvider,
  MSALAuthenticationProviderOptions,
} from '@microsoft/microsoft-graph-client';

const getClientId = () => {
  if (window.location.hostname.includes('itsupport.companionsoftware')) {
    return 'fb0d89d9-98d0-47da-9bd2-81fc1ba1675e';
  }

  return 'c29abe64-1f4b-483b-a7e8-067d37d5d5c2';
};

const getAppId = () => {
  if (window.location.hostname.includes('itsupport.companionsoftware')) {
    return 'a9de7b78-4b6d-4080-81e7-ed0b0ce5707f';
  }

  return 'd518c8b4-a3ef-443e-9cda-8ed7ef338c0e';
};

export const msalInstance = new PublicClientApplication(
  {
    auth: {
      authority: 'https://login.microsoftonline.com/9679ba70-14fe-4e3e-b8cd-830c62a6ef7f', // Directory (tenant) ID Overview blade of App Registration
      clientId: getClientId() // Application (client) ID
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  }
);

export const loginRequest = {
  scopes: [`api://${getAppId()}/.default`],
};

export const authProvider = new ImplicitMSALAuthenticationProvider(
  msalInstance,
  new MSALAuthenticationProviderOptions(['profile']),
);
