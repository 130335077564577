/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_QUEUES,
  GET_TICKETS,
  GET_CUSTOMER_TICKETS,
  GET_EXTERNAL_SUPPORT_TICKETS,
  GET_TICKET,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  OPEN_NEW_MESSAGE,
  CLOSE_NEW_MESSAGE
} from 'src/actions/queueActions';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
  tickets: {
    byId: {},
    allIds: [],
    allColumns: {},
    allTickets: [],
    recordCount: 0,
    parserErrors: [],

  },
  queues: [],
  sidebarOpen: false,
  newMessageOpen: false
};

const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUEUES: {
      const queues = action.payload;

      return produce(state, (draft) => {
        draft.queues = queues;
      });
    }

    case GET_TICKETS: {
      const tickets = action.payload;
      return produce(state, (draft) => {
        draft.tickets.byId = objFromArray(tickets.tickets);
        draft.tickets.allIds = Object.keys(draft.tickets.byId);
        draft.tickets.allColumns = tickets.columns;
        draft.tickets.allTickets = tickets.tickets;
        draft.tickets.recordCount = tickets.recordCount;
        draft.tickets.searchId = tickets.searchId;
        draft.tickets.parserErrors = tickets.parserErrors;
      });
    }

    case GET_CUSTOMER_TICKETS: {
      const tickets = action.payload;
      return produce(state, (draft) => {
        draft.tickets.byId = objFromArray(tickets.tickets);
        draft.tickets.allIds = Object.keys(draft.tickets.byId);
        draft.tickets.recordCount = tickets.recordCount;
        draft.tickets.allColumns = tickets.columns;
        draft.tickets.allTickets = tickets.tickets;
      });
    }

    case GET_EXTERNAL_SUPPORT_TICKETS: {
      const tickets = action.payload;
      return produce(state, (draft) => {
        draft.tickets.byId = objFromArray(tickets.tickets);
        draft.tickets.allIds = Object.keys(draft.tickets.byId);
        draft.tickets.recordCount = tickets.recordCount;
        draft.tickets.allColumns = tickets.columns;
        draft.tickets.allTickets = tickets.tickets;
      });
    }

    case GET_TICKET: {
      const ticket = action.payload;

      return produce(state, (draft) => {
        draft.tickets.byId[ticket.id] = ticket;

        if (!draft.tickets.allIds.includes(ticket.id)) {
          draft.tickets.allIds.push(ticket.id);
        }
      });
    }

    case OPEN_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = true;
      });
    }

    case CLOSE_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = false;
      });
    }

    case OPEN_NEW_MESSAGE: {
      return produce(state, (draft) => {
        draft.newMessageOpen = true;
      });
    }

    case CLOSE_NEW_MESSAGE: {
      return produce(state, (draft) => {
        draft.newMessageOpen = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default queueReducer;
