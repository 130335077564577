import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  TextField,
  InputAdornment
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Menu as MenuIcon,
  Search as SearchIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import { useSelector } from 'react-redux';
import Account from './Account';
import Notifications from './Notifications';
import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  },
  input: {
    color: 'black'
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const { tickets } = useSelector((state) => state.queue);

  const search = (event, value) => {
    const [ticket] = tickets?.allTickets.filter((item) => `${item.id} - ${item.summary}` === value);
    event.target.blur();

    if (ticket) { return history.push(`/customerPortal/tickets/${ticket.id}`); }

    if (value.match(/^[0-9]*$/)) { return history.push(`/customerPortal/tickets/${value}`); }

    return history.push(`/customerPortal/search?q=${value}&order=Id&dir=DESC`);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />

        <Autocomplete
          style={{ width: 300, backgroundColor: '#FFFFFF', borderRadius: 5 }}
          freeSolo
          id="search"
          size="small"
          disableClearable
          options={tickets?.allTickets === undefined ? [] : (tickets?.allTickets?.map((option) => `${option.id} - ${option.summary}`))}
          value={searchValue ?? ''}
          clearOnBlur
          onChange={(e, v) => search(e, v)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              placeholder="Search tickets"
              onChange={(e, v) => setSearchValue(v)}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      style={{ color: 'Grey' }}
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
        <Notifications />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
