/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import CustomerPortalLayout from 'src/layouts/CustomerPortalLayout';
import ExternalSupportPortalLayout from 'src/layouts/ExternalSupportPortalLayout';
import NewsPortalLayout from 'src/layouts/NewsPortalLayout';
import MenuLayout from 'src/layouts/MenuLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/app/reports/myDashboard" />,
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/resetPassword',
    component: lazy(() => import('src/views/auth/ResetPasswordView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/verification/:id/:token',
    component: lazy(() => import('src/views/auth/VerificationView')),
  },
  {
    exact: true,
    path: '/viewSecret/:id/:key?',
    component: lazy(() => import('src/views/secrets/View')),
  },
  {
    exact: true,
    path: '/tickets/feedback/:id/:rating?',
    component: lazy(() => import('src/views/tickets/FeedbackView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/myDashboard" />,
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView')),
      },
      {
        exact: true,
        path: '/app/profile/details/:userId',
        component: lazy(() => import('src/views/pages/ProfileView')),
      },
      {
        exact: true,
        path: '/app/profile/activity/:userId',
        component: lazy(() => import('src/views/pages/ActivityView')),
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView')),
      },
      {
        exact: true,
        path: '/app/reports/myDashboard',
        component: lazy(() => import('src/views/reports/MyDashboardView')),
      },
      {
        exact: true,
        path: '/app/reports/leaderboard',
        component: lazy(() => import('src/views/reports/LeaderboardView')),
      },
      {
        exact: true,
        path: '/app/adminReports',
        component: lazy(() => import('src/views/reports/ReportsView')),
      },
      {
        exact: true,
        path: '/app/adminReports/hostedCustomers',
        component: lazy(() => import('src/views/reports/HostedCustomersView')),
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/myDashboard" />,
      },
      {
        exact: true,
        path: '/app/reports/timesheets',
        component: lazy(() => import('src/views/reports/TimesheetsView')),
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView')),
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() =>
          import('src/views/management/CustomerDetailsView')
        ),
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView')),
      },
      {
        exact: true,
        path: '/app/management/organisation/:organisationId',
        component: lazy(() =>
          import('src/views/management/OrganisationListView')
        ),
      },
      {
        exact: true,
        path: '/app/management/customerBuildVersions',
        component: lazy(() =>
          import('src/views/management/CustomerBuildVersionsListView')
        ),
      },
      {
        exact: true,
        path: '/app/management/customerBuildVersions/createPatch',
        component: lazy(() =>
          import('src/views/management/CustomerBuildVersionsListView/Patches')
        ),
      },
      {
        exact: true,
        path: '/app/tickets/create',
        component: lazy(() => import('src/views/tickets/TicketCreateView')),
      },
      {
        exact: true,
        path: '/app/tickets/migrate',
        component: lazy(() => import('src/views/tickets/Migrate')),
      },
      {
        exact: true,
        path: '/app/data',
        component: lazy(() => import('src/views/data/index')),
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />,
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView')),
      },
      {
        exact: true,
        path: '/app/calendar/shiftTimes',
        component: lazy(() => import('src/views/calendar/ShiftTimesView')),
      },
      {
        exact: true,
        path: '/app/queue/:queueId/:ticketId?',
        component: lazy(() => import('src/views/queues/QueueView')),
      },
      {
        exact: true,
        path: '/app/queue',
        component: () => <Redirect to="/app/queue/1" />,
      },
      {
        exact: false,
        path: '/app/legacysearch/:page?/:recordsPerPage?/:searchString?',
        component: lazy(() => import('src/views/search')),
      },
      {
        exact: false,
        path: '/app/search:searchText?',
        component: lazy(() => import('src/views/query')),
      },
      {
        exact: true,
        path: '/app/wiki/create',
        component: lazy(() => import('src/views/wiki/Create')),
      },
      {
        exact: true,
        path: '/app/wiki/import',
        component: lazy(() => import('src/views/wiki/Import')),
      },
      {
        exact: true,
        path: '/app/wiki/saved',
        component: lazy(() => import('src/views/wiki/Saved')),
      },
      {
        exact: true,
        path: '/app/wiki/:nodeId?/:revisionId?',
        component: lazy(() => import('src/views/wiki')),
      },
      {
        exact: true,
        path: '/app/projects',
        component: lazy(() => import('src/views/management/ProjectListView')),
      },
      {
        exact: true,
        path: '/app/projects/:projectId',
        component: lazy(() =>
          import('src/views/management/ProjectListView/Tasks')
        ),
      },
      {
        exact: true,
        path: '/app/roadmap',
        component: lazy(() => import('src/views/roadmap')),
      },
      {
        exact: true,
        path: '/app/workflows/:id?',
        component: lazy(() => import('src/views/workflows')),
      },
      {
        exact: true,
        path: '/app/secrets',
        component: lazy(() => import('src/views/secrets')),
      },
      {
        exact: true,
        path: '/app/cloudflare',
        component: lazy(() => import('src/views/cloudflareVideos')),
      },
      {
        exact: true,
        path: '/app/cloudflare/new',
        component: lazy(() => import('src/views/cloudflareVideos/NewVideo')),
      },
      {
        exact: true,
        path: '/app/ticketfeedback',
        component: lazy(() => import('src/views/ticketFeedback')),
      },
      {
        exact: true,
        path: '/app/supportAnalytics',
        component: lazy(() => import('src/views/supportAnalytics')),
      },
      {
        exact: true,
        path: '/app/supportAnalyticsNewEdit/:queryId?',
        component: lazy(() => import('src/views/supportAnalyticsNewEdit')),
      },
      {
        exact: true,
        path: '/app/sprints/:sprint?/:location?',
        component: lazy(() => import('src/views/sprints/SprintsView')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '/customerPortal',
    guard: AuthGuard,
    layout: CustomerPortalLayout,
    routes: [
      {
        exact: true,
        path: '/customerPortal',
        component: () => <Redirect to="/customerPortal/tickets" />,
      },
      {
        exact: true,
        path: '/customerPortal/tickets/create',
        component: lazy(() =>
          import('src/views/customerPortal/Tickets/Create')
        ),
      },
      {
        exact: true,
        path: '/customerPortal/tickets/:ticketId?',
        component: lazy(() => import('src/views/customerPortal/Tickets')),
      },
      {
        exact: true,
        path: '/customerPortal/closedTickets',
        component: lazy(() => import('src/views/customerPortal/Tickets')),
      },
      {
        exact: true,
        path: '/customerPortal/actionRequiredTickets',
        component: lazy(() => import('src/views/customerPortal/Tickets')),
      },
      {
        exact: true,
        path: '/customerPortal/articles/:nodeId?',
        component: lazy(() => import('src/views/wiki')),
      },
      {
        exact: true,
        path: '/customerPortal/savedArticles',
        component: lazy(() => import('src/views/wiki/Saved')),
      },
      {
        exact: true,
        path: '/customerPortal/search/',
        component: lazy(() => import('src/views/customerPortal/search')),
      },
      {
        exact: true,
        path: '/customerPortal/account',
        component: lazy(() => import('src/views/pages/CustomerAccountView')),
      },
      {
        exact: true,
        path: '/customerPortal/hosting/usageReport',
        component: lazy(() =>
          import('src/views/customerPortal/hosting/usageReport')
        ),
      },
    ],
  },
  {
    path: '/externalSupportPortal',
    guard: AuthGuard,
    layout: ExternalSupportPortalLayout,
    routes: [
      {
        exact: true,
        path: '/externalSupportPortal',
        component: () => <Redirect to="/externalSupportPortal/tickets" />,
      },
      {
        exact: true,
        path: '/externalSupportPortal/tickets/create',
        component: lazy(() =>
          import('src/views/externalSupportPortal/Tickets/Create')
        ),
      },
      {
        exact: true,
        path: '/externalSupportPortal/tickets/:ticketId?',
        component: lazy(() =>
          import('src/views/externalSupportPortal/Tickets')
        ),
      },
    ],
  },
  {
    path: '/news',
    layout: NewsPortalLayout,
    routes: [
      {
        exact: true,
        path: '/news',
        component: () => <Redirect to="/news/feed/1" />,
      },
      {
        exact: true,
        path: '/news/feed/:pageId?/:articlesPerPage?',
        component: lazy(() => import('src/views/news')),
      },
      {
        exact: true,
        path: '/news/article/:articleId?/:articlesPerPage?',
        component: lazy(() => import('src/views/news/Article')),
      },
      {
        exact: true,
        path: '/news/favourite/:pageId?/:articlesPerPage?',
        component: lazy(() => import('src/views/news/Favourite')),
      },
      {
        exact: true,
        path: '/news/search/:pageId?/:articlesPerPage?',
        component: lazy(() => import('src/views/news/Search')),
      },
    ],
  },
  {
    path: '/menu',
    guard: AuthGuard,
    layout: MenuLayout,
    routes: [
      {
        exact: true,
        path: '/menu/help/:nodeId?',
        component: lazy(() => import('src/views/wiki')),
      },
    ],
  },
  {
    path: '/roadmap',
    layout: MenuLayout,
    routes: [
      {
        exact: true,
        path: '/roadmap',
        component: lazy(() => import('src/views/roadmap')),
      },
    ],
  },
  {
    path: '/browse',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/browse',
        component: () => <Redirect to="/app/reports/myDashboard" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: () => <Redirect to="/app/reports/myDashboard" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
