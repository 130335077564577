/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box, Drawer, Grid, Hidden, IconButton, List, ListSubheader, makeStyles
} from '@material-ui/core';
import {
  BarChart as ReportsIcon,
  BookOpen as ArticleIcon,
  Calendar as CalendarIcon,
  ChevronsLeft as CloseNavBarIcon,
  ChevronsRight as OpenNavBarIcon,
  Clock as TimesheetIcon,
  FilePlus as RaiseRequestIcon,
  Film as CloudflareVideoIcon,
  Key as SecretIcon,
  Layers as ProjectIcon,
  List as QueuesIcon,
  Map as MapIcon,
  PieChart as DashboardIcon,
  Search as TicketsIcon,
  Share2 as WorkflowIcon,
  Star as SavedArticleIcon,
  Tool as BuildIcon,
  User as MyDashboardIcon,
  Users as UsersIcon,
  BarChart2 as LeaderboardIcon,
  Cpu as SupportAnalyticsIcon,
  GitPullRequest as SprintsIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = (navOpen) => [
  {
    subheader: 'Support',
    items: [
      {
        title: 'Queues',
        href: '/app/queue',
        icon: QueuesIcon,
      },
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
      },
      {
        title: 'Raise a request',
        icon: RaiseRequestIcon,
        href: '/app/tickets/create',
      },
      {
        title: 'Projects',
        icon: ProjectIcon,
        href: '/app/projects',
      },
      {
        title: 'Tickets',
        icon: TicketsIcon,
        href: '/app/search',
      },
      {
        title: 'Sprints',
        icon: SprintsIcon,
        href: '/app/sprints',
      },
    ],
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Reports',
        icon: ReportsIcon,
        href: '/app/adminReports',
      },
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/app/reports/dashboard',
      },
      {
        title: 'My Dashboard',
        icon: MyDashboardIcon,
        href: '/app/reports/myDashboard',
      },
      {
        title: 'Leaderboard',
        icon: LeaderboardIcon,
        href: '/app/reports/leaderboard',
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon,
      },
      {
        title: 'Release/Patches',
        href: '/app/management/customerBuildVersions',
        icon: BuildIcon,
      },
      {
        title: 'Timesheets',
        href: '/app/reports/timesheets',
        icon: TimesheetIcon,
      },
      {
        title: 'More',
        items: [
          {
            title: 'Workflows',
            href: '/app/workflows',
            icon: WorkflowIcon,
          },
          {
            title: 'Secrets',
            href: '/app/secrets',
            icon: SecretIcon,
          },
          {
            title: 'Cloudflare Videos',
            href: '/app/cloudflare',
            icon: CloudflareVideoIcon,
          },
          {
            title: 'Roadmap',
            href: '/app/roadmap',
            icon: MapIcon,
          },
          {
            title: 'Ticket Feedback',
            href: '/app/ticketFeedback',
            icon: SavedArticleIcon,
          },
          {
            title: 'Support Analytics',
            href: '/app/supportAnalytics',
            icon: SupportAnalyticsIcon,
          },
        ],
      },
    ],
  },
  {
    subheader: navOpen ? 'Knowledge Base' : 'KB',
    items: [
      {
        title: 'Pages',
        icon: ArticleIcon,
        href: '/app/wiki',
        exact: true,
      },
      {
        title: 'Saved Articles',
        icon: SavedArticleIcon,
        href: '/app/wiki/saved',
        exact: true,
      },
      {
        title: 'Create article',
        icon: RaiseRequestIcon,
        href: '/app/wiki/create',
        exact: true,
      },
    ],
  },
];

const reduceChildRoutes = ({
  acc, pathname, item, depth = 0, navOpen
}) => {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={navOpen ? item.title : ''}
        exact={item.exact}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          navOpen,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={navOpen ? item.title : ''}
        exact={item.exact}
      />
    );
  }

  return acc;
};

const renderNavItems = ({ items, navOpen, ...rest }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        navOpen,
        ...rest,
      }),
      []
    )}
  </List>
);

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const NavBar = ({ openMobile, onMobileClose }) => {
  const classes = useStyles();
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(
    localStorage.getItem('navOpen') !== 'false'
  );

  useEffect(() => {
    const checkNavOpen = () => {
      const item = localStorage.getItem('navOpen');

      if (item) {
        setNavOpen(item === 'true');
      }
    };

    window.addEventListener('storage', checkNavOpen);

    return () => {
      window.removeEventListener('storage', checkNavOpen);
    };
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      style={{ marginTop: openMobile ? 0 : 50 }}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          {navConfig(navOpen || openMobile).map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
                navOpen: navOpen || openMobile,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>

      {openMobile ? null : (
        <div>
          {navOpen ? (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <IconButton
                size="small"
                style={{ marginTop: -50, marginRight: 10 }}
                onClick={() => {
                  localStorage.setItem('navOpen', !navOpen);
                  setNavOpen(!navOpen);
                  window.dispatchEvent(new Event('storage'));
                }}
              >
                <CloseNavBarIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <IconButton
                size="small"
                style={{ marginTop: -50 }}
                onClick={() => {
                  localStorage.setItem('navOpen', !navOpen);
                  setNavOpen(!navOpen);
                  window.dispatchEvent(new Event('storage'));
                }}
              >
                <OpenNavBarIcon />
              </IconButton>
            </Grid>
          )}
        </div>
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: navOpen,
            [classes.drawerClose]: !navOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: navOpen,
              [classes.drawerClose]: !navOpen,
            }),
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
