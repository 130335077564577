import {
  useState,
  useEffect,
  useCallback,
} from 'react';

const useLocalStorage = (key, fallback) => {
  const [value, setValue] = useState(localStorage.getItem(key) || fallback);

  useEffect(() => {
    const checkStorage = () => {
      const newValue = localStorage.getItem(key) || fallback;
      if (value !== newValue) {
        setValue(newValue);
      }
    };

    window.addEventListener('storage', checkStorage);

    return () => {
      window.removeEventListener('storage', checkStorage);
    };
  }, [fallback, key, value]);

  const setStorage = useCallback((newValue) => {
    localStorage.setItem(key, newValue);
    window.dispatchEvent(new Event('storage'));
  }, [key]);

  return [value, setStorage];
};

export default useLocalStorage;
