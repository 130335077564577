/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import {
  List as TicketIcon,
  PlusCircle as CreateTicketIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import axios from 'src/utils/axios';
import NavItem from './NavItem';

const navConfig = [
  {
    subheader: 'Tickets',
    items: [
      {
        title: 'Open Tickets',
        href: '/externalSupportPortal/tickets',
        icon: TicketIcon,
      },
      {
        title: 'Create Ticket',
        href: '/externalSupportPortal/tickets/create',
        icon: CreateTicketIcon,
      },
    ],
  },
];

function renderNavItems({ items, actionRequired, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc, item, actionRequired, ...rest
        }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  actionRequired,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        showAlert={(item.showAlert && actionRequired)}
      >
        {renderNavItems({
          depth: depth + 1,
          actionRequired,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        showAlert={(item.showAlert && actionRequired)}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const [actionRequired, setActionRequired] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const getActionRequired = () => {
    axios.get('/api/ticket/actionRequired')
      .then((response) => {
        setActionRequired(response.data);
      })
      .catch((error) => {
        setActionRequired(false);
        console.error(error);
      });
  };

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      getActionRequired();
    }, 60000);

    return () => clearInterval(interval);
  }, [actionRequired]);

  useEffect(() => {
    setLoaded(false);
    getActionRequired();
  }, [loaded]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname, actionRequired })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
