import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/actions/accountActions';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (window.location.href.toLowerCase().includes('browse/css-')) {
      const ticketId = window.location.href.toLowerCase().substring(window.location.href.toLowerCase().indexOf('css-') + 4, window.location.href.length);
      return history.push(`/app/queue/all/${ticketId}`);
    }

    if (account.user.role === 'Admin') { return undefined; }
    if (account.user.role === 'Dashboard') { return undefined; }
    if (account.user.role === 'User') {
      if (window.location.href.includes('queue/all')) {
        const ticketId = window.location.href.substring(window.location.href.indexOf('all/') + 4, window.location.href.length);
        return history.push(`/customerPortal/tickets/${ticketId}`);
      }
      if (window.location.href.includes('app/wiki')) {
        const articleId = window.location.href.substring(window.location.href.indexOf('wiki') + 4, window.location.href.length);
        return history.push(`/customerPortal/articles${articleId}`);
      }
      return history.push('/customerPortal');
    }
    if (account.user.role === 'ExternalSupport') {
      if (window.location.href.includes('queue/all')) {
        const ticketId = window.location.href.substring(window.location.href.indexOf('all/') + 4, window.location.href.length);

        if (ticketId.includes('queue/all')) return history.push('/externalSupportPortal/tickets');
        return history.push(`/externalSupportPortal/tickets/${ticketId}`);
      }
      return history.push('/externalSupportPortal');
    }
    dispatch(logout());
    return history.push('/');
  });

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
