import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/actions/accountActions';
import TopBar from './TopBar';
import NavBar from './NavBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function CustomerPortalLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (account.user.role === 'User') { return undefined; }
    if (account.user.role === 'Dashboard') { return history.push('/app/reports/Dashboard'); }
    if (account.user.role === 'Admin') {
      if (window.location.href.includes('customerPortal/tickets')) {
        const ticketId = window.location.href.substring(window.location.href.indexOf('tickets/') + 8, window.location.href.length);
        return history.push(`/app/queue/all/${ticketId}`);
      }
      if (window.location.href.includes('customerPortal/articles')) {
        const articleId = window.location.href.substring(window.location.href.indexOf('articles') + 8, window.location.href.length);
        return history.push(`/app/wiki${articleId}`);
      }
      return history.push('/app/reports/Dashboard');
    }
    if (account.user.role === 'ExternalSupport') {
      if (window.location.href.includes('customerPortal/tickets')) {
        const ticketId = window.location.href.substring(window.location.href.indexOf('tickets/') + 8, window.location.href.length);

        if (ticketId.includes('customerPortal/tickets')) return history.push('/externalSupportPortal/tickets');
        return history.push(`/externalSupportPortal/tickets/${ticketId}`);
      }
      return history.push('/externalSupportPortal');
    }
    dispatch(logout());
    return history.push('/');
  });

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

CustomerPortalLayout.propTypes = {
  children: PropTypes.any
};

export default CustomerPortalLayout;
