import axios from 'src/utils/axios';

export const SET_NODES = '@wiki/set-nodes';
export const SET_FLAT_NODES = '@wiki/set-flat-nodes';
export const APPEND_NODE = '@wiki/append-node';
export const SET_ARTICLE = '@wiki/set-article';
export const OPEN_SIDEBAR = '@wiki/open-sidebar';
export const CLOSE_SIDEBAR = '@wiki/close-sidebar';

export const setNodes = (id, nodes) => ({
  type: SET_NODES,
  payload: {
    id,
    nodes,
  },
});

export const getNodesCreator = (id = null) => async (dispatch) => {
  const url = id === null
    ? '/api/wiki/tree'
    : `/api/wiki/tree/${id}`;
  dispatch(setNodes(id, (await axios.get(url)).data));
};

export const setFlatNodes = (nodes) => ({
  type: SET_FLAT_NODES,
  payload: nodes,
});

export const getFlatNodesCreator = () => async (dispatch) => dispatch(setFlatNodes((await axios.get('/api/wiki/nodes')).data));

export const appendNode = (node) => ({
  type: APPEND_NODE,
  payload: node,
});

export const setArticle = (article) => ({
  type: SET_ARTICLE,
  payload: article,
});

export const getArticleCreator = (id, revision) => async (dispatch) => {
  const url = revision
    ? `/api/wiki/${id}/${revision}`
    : `/api/wiki/${id}`;

  try {
    const data = await axios.get(url);
    dispatch(setArticle(data.data));
  } catch {
    dispatch(setArticle(null));
  }
};

export const openSidebar = () => ({
  type: OPEN_SIDEBAR
});

export const closeSidebar = () => ({
  type: CLOSE_SIDEBAR
});
