import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import queueReducer from './queueReducer';
import sprintReducer from './sprintReducer';
import organisationReducer from './organisationReducer';
import wikiReducer from './wikiReducer';
import projectReducer from './projectReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  queue: queueReducer,
  sprint: sprintReducer,
  organisation: organisationReducer,
  form: formReducer,
  wiki: wikiReducer,
  project: projectReducer,
});

export default rootReducer;
