import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

function BadgeIcon({
  OverlayIcon, colour, number, width, height, numberSize,
}) {
  const imageContainerStyle = {
    position: 'relative',
    width: width ?? '200px',
    height: height ?? '200px',
  };

  const imageStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
  };

  const overlayStyle = {
    position: 'absolute',
    width: '50%',
    height: '50%',
    marginLeft: '25%',
    marginTop: '25%',
    color: 'black',
  };

  const overlayLevel = {
    position: 'absolute',
    width: '30%',
    height: '30%',
    marginLeft: '55%',
    marginTop: 2,
    color: 'black',
  };

  const overlayNumber = {
    position: 'absolute',
    marginLeft: '65%',
    marginTop: '6%',
    color: 'black',
    fontSize: numberSize ?? '',
  };

  const getImage = () => {
    if (colour === 'green') {
      return (
        <img alt="" src="/static/images/greenBadge.png" style={imageStyle} />
      );
    }

    if (colour === 'orange') {
      return (
        <img alt="" src="/static/images/orangeBadge.png" style={imageStyle} />
      );
    }

    if (colour === 'purple') {
      return (
        <img alt="" src="/static/images/purpleBadge.png" style={imageStyle} />
      );
    }

    if (colour === 'red') {
      return (
        <img alt="" src="/static/images/redBadge.png" style={imageStyle} />
      );
    }

    return <img alt="" src="/static/images/blueBadge.png" style={imageStyle} />;
  };

  return (
    <div>
      <div style={imageContainerStyle}>
        {getImage()}

        <OverlayIcon style={overlayStyle} />
        {!number ? null : (
          <>
            <img
              alt=""
              src="/static/images/levelBadge.png"
              style={overlayLevel}
            />
            <Typography variant="h1" style={overlayNumber}>
              {number}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}

BadgeIcon.propTypes = {
  OverlayIcon: PropTypes.any.isRequired,
  colour: PropTypes.string,
  number: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  numberSize: PropTypes.number,
};

export default BadgeIcon;
