import {
  SET_NODES,
  SET_FLAT_NODES,
  APPEND_NODE,
  SET_ARTICLE,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
} from 'src/actions/wikiActions';

const initialState = {
  currentNode: null,
  nodes: {},
  flatNodes: [],
  articles: {},
  sidebarOpen: false,
};

const wikiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NODES:
      return {
        ...state,
        currentNode: action.payload.id,
        nodes: {
          ...state.nodes,
          [action.payload.id]: action.payload.nodes,
        },
      };
    case SET_FLAT_NODES:
      return {
        ...state,
        flatNodes: action.payload,
      };
    case APPEND_NODE:
      return {
        ...state,
        flatNodes: [...state.flatNodes, action.payload]
      };
    case SET_ARTICLE:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action?.payload?.id]: action?.payload,
        },
      };
    case OPEN_SIDEBAR:
      return {
        ...state,
        sidebarOpen: true,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: false,
      };
    default:
      return state;
  }
};

export default wikiReducer;
