import axios from 'src/utils/axios';

export const GET_QUEUES = '@queue/get-queues';
export const GET_TICKETS = '@queue/get-tickets';
export const GET_CUSTOMER_TICKETS = '@queue/get-customer-tickets';
export const GET_EXTERNAL_SUPPORT_TICKETS = '@queue/get-external-support-tickets';
export const GET_TICKET = '@queue/get-ticket';
export const OPEN_SIDEBAR = '@queue/open-sidebar';
export const CLOSE_SIDEBAR = '@queue/close-sidebar';
export const OPEN_NEW_MESSAGE = '@queue/open-new-message';
export const CLOSE_NEW_MESSAGE = '@queue/close-new-message';

export function getQueues() {
  const request = axios.get('/api/queue');

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_QUEUES,
      payload: response.data
    }));
  };
}

const getTicketsContinue = (params) => new Promise((resolve, reject) => {
  const {
    queueId,
    q: searchString,
    order,
    dir,
    columns,
    asAtDate,
    page = 1,
    recordsPerPage = 50,
  } = params;

  axios.get('/api/ticket/search', {
    params: {
      queueId,
      searchString,
      order,
      dir,
      columns,
      page,
      recordsPerPage,
      asAtDate,
    }
  })
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export function getTickets(params) {
  return async (dispatch) => {
    const response = await getTicketsContinue(params);
    dispatch({
      type: GET_TICKETS,
      payload: response.data
    });
  };
}

const getTicketContinue = (id) => new Promise((resolve) => {
  axios.get('/api/ticket', {
    params: {
      id
    }
  })
    .then((response) => {
      resolve(response);
    })
    .catch(() => {
      const errorResponse = {
        data: {
          ticket: null
        }
      };
      resolve(errorResponse);
    });
});

export function getTicket(id) {
  return async (dispatch) => {
    const response = await getTicketContinue(id);
    dispatch({
      type: GET_TICKET,
      payload: response.data
    });
  };
}

export function updatedTicket(ticket) {
  return async (dispatch) => {
    dispatch({
      type: GET_TICKET,
      payload: ticket
    });
  };
}

const getCustomerTicketsContinue = (
  all,
  searchString,
  orderBy,
  closed,
  actionRequired,
  page,
  recordsPerPage,
  watchedOnly
) => new Promise((resolve, reject) => {
  axios.get('/api/ticket/customer', {
    params: {
      all,
      searchString,
      orderBy,
      closed,
      actionRequired,
      page,
      recordsPerPage,
      watchedOnly
    }
  })
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export function getCustomerTickets(
  all,
  searchString,
  orderBy,
  closed,
  actionRequired,
  page,
  recordsPerPage,
  watchedOnly
) {
  return async (dispatch) => {
    const response = await getCustomerTicketsContinue(
      all,
      searchString,
      orderBy,
      closed,
      actionRequired,
      page,
      recordsPerPage,
      watchedOnly
    );
    dispatch({
      type: GET_CUSTOMER_TICKETS,
      payload: response.data
    });
  };
}

const getExternalSupportTicketsContinue = () => new Promise((resolve, reject) => {
  axios.get('/api/ticket/externalSupport')
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export function getExternalSupportTickets() {
  return async (dispatch) => {
    const response = await getExternalSupportTicketsContinue();
    dispatch({
      type: GET_EXTERNAL_SUPPORT_TICKETS,
      payload: response.data
    });
  };
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

export function openNewMessage() {
  return {
    type: OPEN_NEW_MESSAGE
  };
}

export function closeNewMessage() {
  return {
    type: CLOSE_NEW_MESSAGE
  };
}
