// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const ENABLE_REDUX_LOGGER = false;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
      // [::1] is the IPv6 localhost address.
      || window.location.hostname === '[::1]'
      // 127.0.0.1/8 is considered localhost for IPv4.
      || window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
);

const getAPIUrl = () => {
  if (process.env.REACT_APP_API_PORT) return `https://localhost:${process.env.REACT_APP_API_PORT}`;
  if (isLocalhost) return 'https://localhost:5001';

  if (window.location.hostname.includes('connectx.staging')) {
    return 'https://connectx-api.staging.companionsoftware.com.au';
  }
  if (window.location.hostname.includes('itsupport.companionsoftware')) {
    return 'https://itsupport-api.companionsoftware.com.au';
  }
  if (window.location.hostname.includes('connectx-demo')) {
    return 'https://connectx-demo-api.companionsoftware.com.au';
  }

  return 'https://connectx-portal-api.companionsoftware.com.au';
};

export const API_BASE_URL = getAPIUrl();

export default {};
