/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_ALL, GET_CUSTOMERS, RESET } from 'src/actions/organisationActions';

const initialState = {
  allCustomers: null,
  customers: null
};

const organisationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL: {
      const customers = action.payload;

      return produce(state, (draft) => {
        draft.allCustomers = customers;
      });
    }

    case GET_CUSTOMERS: {
      const customers = action.payload;

      return produce(state, (draft) => {
        draft.customers = customers;
      });
    }

    case RESET: {
      return produce(state, (draft) => {
        draft.customers = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default organisationReducer;
