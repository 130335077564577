import authService from './services/authService';

const fetchToken = (event) => {
  const port = event.ports[0];

  authService.getAccessToken().then((token) => {
    port.postMessage({
      token,
    });
  });
};

const handleMessage = (event) => {
  const { action } = event.data;

  switch (action) {
    case 'GET_TOKEN':
      fetchToken(event);
      break;
    default:
      console.error('unhandled event', event);
      break;
  }
};

export default handleMessage;
