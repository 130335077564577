import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import mock from 'src/utils/mock';
import _ from 'lodash';

const board = {
  lists: [
    {
      id: 'new',
      name: 'New',
    },
    {
      id: 'readyToImplement',
      name: 'Ready to Implement',
    },
    {
      id: 'implementing',
      name: 'Implementing',
    },
    {
      id: 'codeReview',
      name: 'Code Review',
    },
    {
      id: 'testing',
      name: 'Testing',
    },
    {
      id: 'review',
      name: 'Review',
    },
    {
      id: 'closed',
      name: 'Closed',
    },
  ],
  cards: [
    {
      id: '16137',
      listId: 'testing',
      ticketType: 'Bug',
      title: 'Truck Odometer Report - Multiple Changes',
      tags: ['OC'],
      assignee: null,
      originalEstimate: 0,
      completed: 1,
      remaining: 0,
    },
    {
      id: '15770',
      listId: 'testing',
      ticketType: 'Enhancement',
      title: 'Xero API - Changes',
      tags: ['OC'],
      assigneeId: null,
      originalEstimate: 0,
      completed: 16,
      remaining: 0,
    },
    {
      id: '16061',
      listId: 'testing',
      ticketType: 'Enhancement',
      title:
        'Fuel Companion - Option to attaach documents to a Customer Order(delivery)',
      tags: ['FC'],
      assigneeId: null,
      originalEstimate: 4,
      completed: 4,
      remaining: 0,
    },
    {
      id: '16138',
      listId: 'new',
      ticketType: 'Bug',
      title: 'TESTO',
      tags: ['OC'],
      assigneeId: '5e887a62195cc5aef7e8ca5d',
      originalEstimate: 1,
      completed: 1,
      remaining: 5,
    },
    {
      id: '16070',
      listId: 'new',
      ticketType: 'Bug',
      title: 'Asset Expense Report',
      tags: ['OC'],
      assigneeId: '5e887a62195cc5aef7e8ca1d',
      originalEstimate: 2,
      completed: 0,
      remaining: 2,
    },
    {
      id: '15717',
      listId: 'implementing',
      ticketType: 'Enhancement',
      title: 'Product Level Template - Tank invoice and quote template',
      tags: ['OC'],
      assigneeId: '5e887a62195cc5aef7e8ca1d',
      originalEstimate: 6,
      completed: 1,
      remaining: 6,
    },
    {
      id: '16108',
      listId: 'implementing',
      ticketType: 'Bug',
      title:
        'Fuel Companion - Pro Active Fatigue colouring when in a break or overdue',
      tags: ['OC'],
      assigneeId: '5e887a62195cc5aef7e8ca1d',
      originalEstimate: 3,
      completed: 3,
      remaining: 0,
    },
  ],
  members: [
    {
      id: null,
      name: 'Unassigned',
      avatar: '',
    },
    {
      id: '5e887a62195cc5aef7e8ca1d',
      name: 'Gareth Riley',
      avatar: '',
    },
    {
      id: '5e887a62195cc5aef7e8ca5d',
      name: 'Kyle Goonrey',
      avatar: '/static/images/avatars/avatar_5.png',
    },
  ],
};

mock.onGet('/api/kanban/board').reply(200, {
  board,
});

mock.onPost('/api/kanban/board/lists/new').reply((request) => {
  const { name } = JSON.parse(request.data);
  const list = {
    id: uuidv4(),
    name,
    cardIds: [],
  };

  _.assign(board, {
    lists: [...board.lists, list],
  });

  return [200, { list }];
});

mock.onPost('/api/kanban/board/list/update').reply((request) => {
  const { listId, update } = JSON.parse(request.data);
  const list = board.lists.find((_list) => _list.id === listId);

  _.assign(list, update);

  return [200, { list }];
});

mock.onPost('/api/kanban/board/lists/clear').reply((request) => {
  const { listId } = JSON.parse(request.data);

  _.assign(board, {
    lists: _.map(board.lists, (list) => {
      if (list.id === listId) {
        _.assign(list, {
          cardIds: [],
        });
      }

      return list;
    }),
    cards: board.cards.filter((card) => card.listId !== listId),
  });

  return [200, { listId }];
});

mock.onPost('/api/kanban/board/lists/remove').reply((request) => {
  const { listId } = JSON.parse(request.data);

  _.assign(board, {
    lists: _.reject(board.lists, { id: listId }),
    cards: board.cards.filter((card) => card.listId !== listId),
  });

  return [200, { listId }];
});

mock.onPost('/api/kanban/board/cards/new').reply((request) => {
  const { listId, name } = JSON.parse(request.data);
  const card = {
    id: uuidv4(),
    attachments: [],
    checklists: [],
    comments: [],
    cover: null,
    description: null,
    due: null,
    isSubscribed: false,
    listId,
    memberIds: [],
    name,
  };

  _.assign(board, {
    cards: [...board.cards, card],
    lists: _.map(board.lists, (list) => {
      if (list.id === listId) {
        _.assign(list, {
          cardIds: [...list.cardIds, card.id],
        });
      }

      return list;
    }),
  });

  return [200, { card }];
});

mock.onPost('/api/kanban/board/cards/update').reply((request) => {
  const { cardId, update } = JSON.parse(request.data);
  const card = board.cards.find((_card) => _card.id === cardId);

  _.assign(card, update);

  return [200, { card }];
});

mock.onPost('/api/kanban/board/cards/move').reply((request) => {
  const { cardId, listId } = JSON.parse(request.data);
  const card = board.cards.find((_card) => _card.id === cardId);
  card.listId = listId;

  return [200, true];
});

mock.onPost('/api/kanban/board/cards/remove').reply((request) => {
  const { cardId } = JSON.parse(request.data);

  _.assign(board, {
    cards: _.reject(board.cards, { id: cardId }),
    lists: _.map(board.lists, (list) => {
      _.assign(list, {
        cardIds: list.cardIds.filter((_cardId) => _cardId !== cardId),
      });

      return list;
    }),
  });

  return [200, { cardId }];
});

mock.onPost('/api/kanban/board/cards/comments').reply((request) => {
  const { userId, cardId, message } = JSON.parse(request.data);
  const comment = {
    id: uuidv4(),
    cardId,
    createdAt: moment()
      .toDate()
      .getTime(),
    memberId: userId,
    message,
  };

  _.assign(board, {
    cards: _.map(board.cards, (card) => {
      _.assign(card, {
        comments: [...card.comments, comment],
      });

      return card;
    }),
  });

  return [200, { comment }];
});

mock.onPost('/api/kanban/board/checklists/new').reply((request) => {
  const { cardId, name } = JSON.parse(request.data);
  const checklist = {
    id: uuidv4(),
    name,
    checkItems: [],
  };

  _.assign(board, {
    cards: _.map(board.cards, (card) => {
      if (card.id === cardId) {
        _.assign(card, {
          checklists: [...card.checklists, checklist],
        });
      }

      return card;
    }),
  });

  return [200, { checklist }];
});

mock.onPost('/api/kanban/board/checklists/update').reply((request) => {
  const { cardId, checklistId, update } = JSON.parse(request.data);
  let checklist = null;

  _.assign(board, {
    cards: _.map(board.cards, (card) => {
      if (card.id === cardId) {
        _.map(card.checklists, (_checklist) => {
          if (_checklist.id === checklistId) {
            _.assign(_checklist, { ...update });
            checklist = _checklist;
          }

          return _checklist;
        });
      }

      return card;
    }),
  });

  return [200, { checklist }];
});

mock.onPost('/api/kanban/board/checklists/remove').reply((request) => {
  const { cardId, checklistId } = JSON.parse(request.data);

  _.assign(board, {
    cards: _.map(board.cards, (card) => {
      if (card.id === cardId) {
        _.assign(card, {
          checklists: _.reject(card.checklists, { id: checklistId }),
        });
      }

      return card;
    }),
  });

  return [200, true];
});

mock.onPost('/api/kanban/board/checkitems/new').reply((request) => {
  const { cardId, checklistId, name } = JSON.parse(request.data);
  const checkItem = {
    id: uuidv4(),
    checklistId,
    name,
    status: 'incomplete',
  };

  _.assign(board, {
    cards: _.map(board.cards, (card) => {
      if (card.id === cardId) {
        _.assign(card, {
          checklists: card.checklists.map((checklist) => {
            if (checklist.id === checklistId) {
              _.assign(checklist, {
                checkItems: [...checklist.checkItems, checkItem],
              });
            }

            return checklist;
          }),
        });
      }

      return card;
    }),
  });

  return [200, { checkItem }];
});

mock.onPost('/api/kanban/board/checkitems/update').reply((request) => {
  const {
    cardId, checklistId, checkItemId, update
  } = JSON.parse(request.data);
  let checkItem = null;

  _.assign(board, {
    cards: _.map(board.cards, (card) => {
      if (card.id === cardId) {
        _.assign(card, {
          checklists: _.map(card.checklists, (checklist) => {
            if (checklist.id === checklistId) {
              _.assign(checklist, {
                checkItems: _.map(checklist.checkItems, (_checkItem) => {
                  if (_checkItem.id === checkItemId) {
                    _.assign(_checkItem, { ...update });
                    checkItem = _checkItem;
                  }

                  return _checkItem;
                }),
              });
            }

            return checklist;
          }),
        });
      }

      return card;
    }),
  });

  return [200, { checkItem }];
});

mock.onPost('/api/kanban/board/checkitems/remove').reply((request) => {
  const { cardId, checklistId, checkItemId } = JSON.parse(request.data);

  _.assign(board, {
    cards: _.map(board.cards, (card) => {
      if (card.id === cardId) {
        _.assign(card, {
          checklists: _.map(card.checklists, (checklist) => {
            if (checklist.id === checklistId) {
              _.assign(checklist, {
                checkItems: _.reject(checklist.checkItems, { id: checkItemId }),
              });
            }

            return checklist;
          }),
        });
      }

      return card;
    }),
  });

  return [200, true];
});
