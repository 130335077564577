/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_ALL, GET_PROJECT } from 'src/actions/projectActions';

const initialState = {
  projects: null,
  tasks: null
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL: {
      const projects = action.payload;

      return produce(state, (draft) => {
        draft.projects = projects;
      });
    }

    case GET_PROJECT: {
      const tasks = action.payload;

      return produce(state, (draft) => {
        draft.tasks = tasks;
      });
    }

    default: {
      return state;
    }
  }
};

export default projectReducer;
