import axios from 'axios';
import { API_BASE_URL } from 'src/config';
import authService from 'src/services/authService';

const instance = axios.create();
instance.defaults.baseURL = API_BASE_URL;

instance.interceptors.request.use(
  (config) => authService.getAccessToken().then((token) => {
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return config;
  }).catch((error) => {
    console.error(error);
  }),
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
