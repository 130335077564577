import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
  Badge
} from '@material-ui/core';
import {
  Bell as BellIcon,
  MessageCircle as MessageIcon,
} from 'react-feather';
import { getNotifications } from 'src/actions/notificationsActions';
import axios from 'src/utils/axios';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  badge: {
    fontSize: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },

}));

function Notifications() {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notifications.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (notification) => {
    if (notification.portalSeen) {
      setOpen(false);
      return;
    }

    axios.put(`/api/notification/${notification.id}/markAsRead`)
      .then(() => {
        dispatch(getNotifications());
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);
      });
  };

  const markAllAsRead = () => {
    if (notifications.filter((x) => !x.portalSeen).length === 0) {
      setOpen(false);
      return;
    }

    axios.put('/api/notification/markAllAsRead', null)
      .then(() => {
        dispatch(getNotifications());
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);
      });
  };

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      dispatch(getNotifications());
    }, 300000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    setLoaded(false);
    dispatch(getNotifications());
  }, [loaded, dispatch]);

  return (
    <>
      <Tooltip title="Notifications">
        <Badge
          color="error"
          badgeContent="New"
          classes={{ badge: classes.badge }}
          invisible={(notifications.filter((x) => !x.portalSeen).length === 0)}
        >
          <IconButton
            color="inherit"
            ref={ref}
            onClick={handleOpen}
          >
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List
              className={classes.list}
              disablePadding
            >
              {notifications.map((notification) => (
                <ListItem
                  style={{
                    backgroundColor: (!notification.portalSeen
                      ? theme.palette.background.calendar
                      : null
                    )
                  }}
                  className={classes.listItem}
                  component={RouterLink}
                  divider
                  key={notification.id}
                  to={notification.portalLink}
                  onClick={() => handleClick(notification)}
                >
                  <ListItemAvatar>
                    <Avatar
                      className={classes.icon}
                    >
                      <SvgIcon fontSize="small">
                        <MessageIcon />
                      </SvgIcon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.portalTitle}
                    primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                    secondary={notification.portalDescription}
                  />
                </ListItem>
              ))}
            </List>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                size="small"
                onClick={markAllAsRead}
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
