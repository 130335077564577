import axios from 'src/utils/axios';

export const GET_ALL = '@projects/get-all';
export const GET_PROJECT = '@projects/get-project';

export function getAll() {
  const request = axios.get('/api/project');

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_ALL,
      payload: response.data
    }));
  };
}

export function getProject(id) {
  const request = axios.get(`/api/project/${id}`);

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_PROJECT,
      payload: response.data
    }));
  };
}
