import axios from 'src/utils/axios';

export const GET_BOARD = '@sprint/get-board';
export const MOVE_CARD = '@sprint/move-card';

export function getBoard(sprintName) {
  const request = axios.get('/api/sprint/board', {
    params: {
      sprintName,
    },
  });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: GET_BOARD,
      payload: response.data,
    }));
  };
}

export function moveCard(cardId, position, listId, sprintName, commitMove) {
  let request = async () => {};
  if (commitMove) {
    request = axios.post('/api/sprint/board/cards/move', {
      cardId,
      position,
      listId,
    });
  }

  return (dispatch) => {
    request
      .then(
        dispatch({
          type: MOVE_CARD,
          payload: {
            cardId,
            position,
            listId,
          },
        })
      )
      .then(() => {
        if (commitMove) {
          dispatch(getBoard(sprintName));
        }
      });
  };
}
