import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Link,
  Typography,
  makeStyles,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Grid,
} from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'src/authProvider';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import OCLogo from 'src/components/OCLogo';
import EdgePOSLogo from 'src/components/EdgePOSLogo';
import useLocalStorage from 'src/utils/useLocalStorage';
import authService from 'src/services/authService';
import axios from 'src/utils/axios';
import LoginForm from './LoginForm';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 79,
    width: 94,
  },
  edgePosIcon: {
    backgroundColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 79,
  },
  cell: {
    borderBottom: 'none',
  },
  logo: {
    marginTop: -50,
    filter:
      theme.palette.type === 'light' ? 'brightness(0%)' : 'brightness(100%)',
  },
}));

function LoginView() {
  const classes = useStyles();
  const [user] = useLocalStorage('loggedInUser');
  const { instance } = useMsal();

  const handleSubmitSuccess = () => {
    window.location.reload();
  };

  async function itLogin() {
    const loginResponse = await instance.loginPopup(loginRequest);

    localStorage.setItem('azureUsername', loginResponse.account.username);

    const userDetails = (await axios.get('/api/user/me')).data;
    localStorage.setItem('loggedInUser', JSON.stringify(userDetails));

    window.location.reload();
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    authService.getAccessToken().then((token) => {
      if (authService.isValidToken(token)) {
        window.location.reload();
      }
    });
  }, [user]);

  const isEdgePOS = () => {
    if (window.location.hostname.includes('edgepos')) {
      return true;
    }

    return false;
  };

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            {isEdgePOS() ? (
              <EdgePOSLogo className={classes.edgePosIcon} />
            ) : (
              <Avatar className={classes.icon}>
                <OCLogo />
              </Avatar>
            )}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Logo className={classes.logo} />
            </Grid>
            <Typography variant="h2" color="textPrimary">
              Sign in
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {`Sign in to ConnectX - The ${
                isEdgePOS() ? 'Edge POS' : 'Companion Software'
              } Portal`}
            </Typography>
            <Box mt={3}>
              <LoginForm onSubmitSuccess={handleSubmitSuccess} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell} align="left">
                    <Link
                      component={RouterLink}
                      to="/resetPassword"
                      variant="body2"
                      color="textSecondary"
                    >
                      Forgot password
                    </Link>
                  </TableCell>
                  <TableCell className={classes.cell} align="right">
                    <Button onClick={() => itLogin()}>IT Login</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default LoginView;
