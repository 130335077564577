import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthGuard = ({ children }) => {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    if (window.location.pathname === '/app/reports/Dashboard') {
      return <Redirect to="/login" />;
    }

    const query = new URLSearchParams(window.location.search);
    query.append('return', window.location.pathname);
    return <Redirect to={`/login?${query.toString()}`} />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
